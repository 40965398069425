.wallet-element {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
}

.wallet-block {
  padding: 50px;
  border-radius: 20px;
  text-align: center;
  border: 1px solid #d1d1d1;
}

.wallet-btn {
  border-radius: 10px;
  border: 1px solid #d1d1d1;
  background-color: transparent;
  padding: 20px;
  display: block;
  margin: 0px auto;
  margin-bottom: 10px;
}

.btn-blue {
  color: blue;
}

.wallet-btn:hover {
  color: black;
}

.btn-green {
  color: green;
}